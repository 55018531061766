import React from "react"
import Wrapper from "../components/wrapper";
import SectionTitle from "../components/sectionTitle";
import { graphql } from 'gatsby'

const Job = ({data}) => {

    const item = data.jobsJson;

    return (
        <Wrapper
            customTitle={`${item.title} | Career at Appleseeds, Malaysia (KL)`} 
            customDescription={item.ogDescription || "If you heard your calling for saving the world from bad websites, you have come to the right place. Join us on this mission to create great work together."} 
            customKeyword={item.ogKeyword || "web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"} 
            customOgImage={"/cover-about.jpg"}
            slug={`/jobs/${item.jsonId}/`}
            >
            <section className="bg-brand-dark-blue pt-36 pb-20 md:pb-24">
                <div className="container mx-auto">
                    <SectionTitle
                        wrapperClassName=""
                        alignCenter={true}
                        title={item.title}
                        minititle={item.type}
                        type="h1"
                        invert={true}
                        childrenClassName="lg:w-1/2"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                    >
                    </SectionTitle>
                </div>
            </section>
            <section className="py-16">
                <div className="container mx-auto">
                    <article className="md:w-2/3 mx-auto">
                        <div className="flex flex-col space-y-6">
                            <div className="">
                                <h4 className="font-normal text-brand-gold mb-4">
                                    Job Description
                                </h4>
                                {item.jobDescription.map((item, index) => {
                                    return (
                                        <p className="mb-3 text-base md:text-1.5xl leading-relaxed text-opacity-90" key={index}>
                                            {item}
                                        </p>
                                    )
                                })}
                            </div>
                            <div className="pb-5">
                                <h4 className="font-normal text-brand-gold mb-4">
                                    Responsibilities
                                </h4>
                                <ul className="flex flex-col space-y-2 pl-5 list-disc text-base md:text-1.5xl leading-relaxed text-opacity-90">
                                    {item.responsibilities.map((item, index) => {
                                        return (
                                            <li className="" key={index}>
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="pb-5">
                                <h4 className="font-normal text-brand-gold mb-4">
                                    Requirements
                                </h4>
                                <ul className="flex flex-col space-y-2 pl-5 list-disc text-base md:text-1.5xl leading-relaxed text-opacity-90">
                                    {item.requirements.map((item, index) => {
                                        return (
                                            <li className="" key={index}>
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="pb-5">
                                <h4 className="font-normal text-brand-gold mb-4">
                                    Working with us
                                </h4>
                                <ul className="flex flex-col space-y-2 pl-5 list-disc text-base md:text-1.5xl leading-relaxed text-opacity-90">
                                    {item.perks.map((item, index) => {
                                        return (
                                            <li className="" key={index}>
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="">
                                <h4 className="font-normal text-brand-gold mb-4">
                                    Apply Now
                                </h4>
                                <p className="mb-3 text-base md:text-1.5xl leading-relaxed text-opacity-90">
                                    Please email your resume, cover letter, and portfolio to <a href="mailto:jobs@appleseeds.my" target="_blank" rel="noopener noreferrer" className="font-semibold text-brand-dark-blue transition-ease-in hover:text-opacity-80">jobs@appleseeds.my</a>. Only shortlisted candidates will be contacted.
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </Wrapper>
    );
};


export default Job;

export const query = graphql`
query($jsonId: String) {
    jobsJson(jsonId: {eq: $jsonId}) {
      id
      type
      title
      shortDescription
      responsibilities
      requirements
      perks
      ogKeyword
      ogDescription
      jsonId
      jobDescription
    }
  }
`